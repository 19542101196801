import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/SoftwareStats.css";
import { getErrorStats } from "../../services/apiService.js";
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from "xlsx";

const softwareData = [
  //{ software_config: "2024-08-27_stable", linux_sw_version: "1.4.1", gui_sw_version: "1.3.1.3", imx7_m4_version: "1.7.0.0", fcl_version: "2.5.0.0", a7_sw_version: "2.2.0.0", a7_control_version: "1.3.0.0", STM32_BL: "1.1.1", stm32_m4_version: "2.5.0.30", stm8_pri_fw_build: "2.6.1.0" },
  //{ software_config: "2024-09-20_stable", linux_sw_version: "1.1.1.0", gui_sw_version: "1.4.0.0", imx7_m4_version: "1.8.0.0", fcl_version: "2.6.0.0", a7_sw_version: "2.3.0.0", a7_control_version: "1.4.0.0", STM32_BL: "1.2.0.0", stm32_m4_version: "2.6.1.0", stm8_pri_fw_build: "2.7.0.0" },
  //{ software_config: "2024-10-07_release", linux_sw_version: "1.1.1.0", gui_sw_version: "1.5.0.0", imx7_m4_version: "1.9.0.0", fcl_version: "2.8.0.0", a7_sw_version: "2.4.0.0", a7_control_version: "1.5.0.0", STM32_BL: "1.2.0.0", stm32_m4_version: "2.6.2.3", stm8_pri_fw_build: "2.8.2.0" },
  //{ software_config: "2024-11-08_release", linux_sw_version: "1.1.1.0", gui_sw_version: "1.6.0.0", imx7_m4_version: "1.10.0.0", fcl_version: "2.10.0.0", a7_sw_version: "2.5.0.0", a7_control_version: "1.5.1.0", STM32_BL: "1.2.0.0", stm32_m4_version: "2.7.0.0", stm8_pri_fw_build: "2.8.2.0" },
  //{ software_config: "2024-12-06_integration", linux_sw_version: "1.5.0.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.11.0.0", fcl_version: "2.11.0.0", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", STM32_BL: "1.2.0.0", stm32_m4_version: "2.8.0.0", stm8_pri_fw_build: "2.8.3.0" },
  //{ software_config: "2024-12-06_hotfix-1_release", linux_sw_version: "1.5.0.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.11.0.0", fcl_version: "2.11.0.0", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", STM32_BL: "1.2.0.0", stm32_m4_version: "2.8.0.2", stm8_pri_fw_build: "2.8.3.0" },
  //{ software_config: "2024-12-06_hotfix-2_integration", linux_sw_version: "1.5.0.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.11.0.0", fcl_version: "2.11.0.0", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", STM32_BL: "1.2.0.0", stm32_m4_version: "2.8.0.2", stm8_pri_fw_build: "2.8.4.0" },
  //{ software_config: "2136_dev_testing", linux_sw_version: "1.5.0.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.10.0.36", fcl_version: "2.10.1.9", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", STM32_BL: "1.2.0.0", stm32_m4_version: "2.7.0.24", stm8_pri_fw_build: "2.8.3.0" },
  
  { software_config: "2136_dev_testing", linux_sw_version: "1.5.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.10.0.36", fcl_version: "2.10.1.9", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2",  stm32_m4_version: "2.7.0.24", stm8_pri_fw_build: "2.8.3.0" },
  { software_config: "2024-12-06_hotfix-2_integration", linux_sw_version: "1.5.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.11.0.0", fcl_version: "2.11.0.0", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", stm32_m4_version: "2.8.0.2", stm8_pri_fw_build: "2.8.4.0" },
  { software_config: "2024-12-06_hotfix-1_release", linux_sw_version: "1.5.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.11.0.0", fcl_version: "2.11.0.0", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", stm32_m4_version: "2.8.0.2", stm8_pri_fw_build: "2.8.3.0" },
  { software_config: "2024-12-06_integration", linux_sw_version: "1.5.0", gui_sw_version: "1.7.0.0", imx7_m4_version: "1.11.0.0", fcl_version: "2.11.0.0", a7_sw_version: "2.6.0.2", a7_control_version: "1.6.0.2", stm32_m4_version: "2.8.0.0", stm8_pri_fw_build: "2.8.3.0" },
  { software_config: "2024-11-08_release", imx7_m4_version: "1.10.0.0", fcl_version: "2.10.0.0", a7_sw_version: "2.5.0.0", stm32_m4_version: "2.7.0.0", stm8_pri_fw_build: "2.8.2.0" },
  { software_config: "2024-10-07_release", imx7_m4_version: "1.9.0.0", fcl_version: "2.8.0.0", a7_sw_version: "2.4.0.0", stm32_m4_version: "2.6.2.3", stm8_pri_fw_build: "2.8.2.0" },
];

const SoftwareVersionTable = () => {
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [errorStats, setErrorStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedDownloadConfig, setSelectedDownloadConfig] = useState(null);
  const [downloadedData, setDownloadedData] = useState(null); // Store fetched data
  const currDate = new Date();


  useEffect(() => {
    async function fetchAllData() {
      const results = {};
      setLoading(true);

      for (const config of softwareData) {
        try {
          const apiData = await getErrorStats(config);
          results[config.software_config] = {
            totalRuns: apiData.totalCount || 0,
            runsWithErrors: apiData.errorFrequencies
              ? apiData.errorFrequencies.reduce(
                  (sum, entry) => (entry.errorcode !== null ? sum + entry.count : sum),
                  0
                )
              : 0,
            errorFrequencies: apiData.errorFrequencies || []
          };
        } catch (error) {
          console.error(`Error fetching data for ${config.software_config}:`, error);
          results[config.software_config] = { totalRuns: 0, runsWithErrors: 0, errorFrequencies: [] };
        }
      }

      setErrorStats(results);
      setLoading(false);
    }

    fetchAllData();
  }, []);

  // useEffect for handling download data fetching
  useEffect(() => {
    if (!selectedDownloadConfig) return; // Prevent running if no download request

    const fetchDownloadData = async () => {
      try {
        console.log(`Fetching individual runs for: ${selectedDownloadConfig.software_config}`);
        const apiData = await getErrorStats({ ...selectedDownloadConfig, individualRuns: true }); // ✅ Send individualRuns flag
        setDownloadedData(apiData); // Store fetched data
        console.log("Fetched run data:", apiData); // Simulate handling the data
      } catch (error) {
        console.error("Error fetching individual runs:", error);
      }
    };

    fetchDownloadData();
  }, [selectedDownloadConfig]);

  // Generate and Download Excel once data is available
  useEffect(() => {
    if (!downloadedData || !selectedDownloadConfig) return; // Wait until data is available

    const generateExcel = () => {
      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert Software Version Details into key-value pairs
      const softwareDetailsKeys = [Object.keys(selectedDownloadConfig)]; // First row (Keys)
      const softwareDetailsValues = [Object.values(selectedDownloadConfig)]; // Second row (Values)

      // Add Two Blank Rows
      const blankRows = [[""], [""]];
      
      // Convert Runs Data into an array with headers
      const runsData = downloadedData?.data || []; // Extract `data` from API response
      const runHeaders = ["Test #", "Test ID", "Serial #", "Delivery Rate", "Alarm Status", "Error Code", "Time Remaining", "Exclude Flag", "Start Time", "End Time"];
      const runValues = runsData.map(run => [
        run.test_number,
        run.id,
        run.infdev_sn,
        run.delivery_rate,
        run.alarm_status,
        run.errorcode,
        run.delivery_time_remaining,
        run.exclude_flag,
        convertToEST(run.test_start_time),
        convertToEST(run.test_end_time)
      ]);

      // First Sheet: "Error Stats"
      // Software Version Stats Row (From the main table)
      const stats = errorStats[selectedDownloadConfig.software_config] || { totalRuns: 0, runsWithErrors: 0 };
      const percentWithErrors = stats.totalRuns 
        ? ((stats.runsWithErrors / stats.totalRuns) * 100).toFixed(2) 
        : "0.00";
      
      const softwareStatsHeaders = [["Software Configuration", "Total Runs", "Runs with Errors", "Percent of Runs with Errors"]];
      const softwareStatsValues = [[
        selectedDownloadConfig.software_config,
        stats.totalRuns,
        stats.runsWithErrors,
        `${percentWithErrors}%`
      ]];

      // Error Statistics Table (Only if runsWithErrors > 0)
      const errorHeaders = [["Error Code", "Number of Errors", "Percent of Errors", "Percent of Total Runs"]];
      const errorValues = stats.runsWithErrors > 0 
        ? stats.errorFrequencies
            .filter(error => error.count > 0) // Hide errors with count = 0
            .map(error => [
              error.errorcode || "N/A",
              error.count,
              ((error.count / stats.runsWithErrors) * 100).toFixed(2) + "%",
              ((error.count / stats.totalRuns) * 100).toFixed(2) + "%"
            ])
        : [["No errors recorded"]];

      // Build Error Stats Sheet
      const errorStatsSheet = [
        ...softwareDetailsKeys,  // Software Version Details
        ...softwareDetailsValues,
        ...blankRows,            // Two Blank Rows
        ...softwareStatsHeaders, // Software Stats Table Headers
        ...softwareStatsValues,  // Software Stats Table Values
        ...blankRows,            // Two Blank Rows
        ...errorHeaders,         // Error Stats Table Headers
        ...errorValues           // Error Stats Table Values
      ];

      const ws1 = XLSX.utils.aoa_to_sheet(errorStatsSheet);
      XLSX.utils.book_append_sheet(wb, ws1, "Error Stats");


      // Second sheet - run data
      const runDataSheet = [
        ...softwareDetailsKeys,   // Software Version Details (first 2 rows)
        ...softwareDetailsValues,
        ...blankRows,          //  2 blank rows   
        runHeaders,           // Runs Data Table Headers
        ...runValues          // Runs Data
      ];

      // Create the worksheet and add it to the workbook
      const ws2 = XLSX.utils.aoa_to_sheet(runDataSheet);
      ws2["!cols"] = runHeaders.map((_, index) => ({
        wch: Math.max(...runValues.map(row => (row[index] ? row[index].toString().length : 10)), runHeaders[index].length) + 2
      }));
      addAutoFilter(ws2, softwareDetailsKeys.length + softwareDetailsValues.length + blankRows.length, softwareDetailsKeys.length + softwareDetailsValues.length + blankRows.length + runValues.length, runHeaders.length - 1);
      XLSX.utils.book_append_sheet(wb, ws2, "Run Data");

      const now = new Date(currDate.getTime() - 5 * 60 * 60 * 1000)
      const formattedDate = now.toISOString().slice(0, 19).replace("T", "_").replace(/:/g, "-");
      XLSX.writeFile(wb, `${selectedDownloadConfig.software_config}_${formattedDate}.xlsx`);
      
      // Reset state after download
      setSelectedDownloadConfig(null);
      setDownloadedData(null);
    };

    generateExcel();
  }, [downloadedData]); // Runs when run data is available

  // Handle Download Click
  const handleDownload = (config) => {
    setSelectedDownloadConfig(config); // Trigger useEffect by setting state
  };

  // Function to Add AutoFilter for Run Data Table
  const addAutoFilter = (ws, startRow, endRow, endCol) => {
    ws["!autofilter"] = {
      ref: XLSX.utils.encode_range({
        s: { r: startRow, c: 0 }, 
        e: { r: endRow, c: endCol }
      }) // AutoFilter from headers to last row
    };
  };

  // Function to convert UTC to EST
  const convertToEST = (utcDate) => {
    if (!utcDate) return "N/A"; // Handle empty/null values

    const date = new Date(utcDate); // Convert to Date object
    return date.toLocaleString("en-US", { timeZone: "America/New_York" }); // Convert to EST
  };

  
  return (
    <Container className="mt-5">
      <div className="p-4 bg-light rounded shadow-sm">
        <h3 className="text-center mb-4">Software Version Stats</h3>
        <div className="table-responsive">
          <Table striped bordered hover className="shadow-sm">
            <thead className="table-light">
              <tr>
                <th>Software Configuration</th>
                <th>Total Runs</th>
                <th>Runs with Errors</th>
                <th>Percent of Runs with Errors</th>
                <th className="text-center">Download Run Data</th>
              </tr>
            </thead>
            <tbody>
              {softwareData.map((config, index) => {
                const stats = errorStats[config.software_config] || { totalRuns: 0, runsWithErrors: 0 };
                const percentWithErrors = stats.totalRuns
                  ? ((stats.runsWithErrors / stats.totalRuns) * 100).toFixed(2)
                  : "0.00";
                const isSelected = selectedConfig?.software_config === config.software_config;

                return (
                  <React.Fragment key={index}>
                    {/* Clickable Row */}
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedConfig(isSelected ? null : config)}
                    >
                      <td className="fw-bold">{config.software_config}</td>
                      <td className="text-center">{loading ? "Loading..." : stats.totalRuns}</td>
                      <td className="text-center">{loading ? "Loading..." : stats.runsWithErrors}</td>
                      <td className="text-center">{loading ? "Loading..." : `${percentWithErrors}%`}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-link text-primary"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click from toggling the accordion
                            handleDownload(config);
                          }}
                        >
                          <DownloadIcon fontSize="small" /> 
                        </button>
                      </td>
                    </tr>

                    {/* Expanded Details Row */}
                    {isSelected && (
                      <tr>
                        <td colSpan="5">
                          <div className="p-3 bg-light rounded shadow-sm">
                            <h5 className="text-center mb-3">Software Version Details</h5>
                            <div className="table-responsive">
                              <Table striped bordered className="shadow-sm" size="sm">
                                <thead className="table-light">
                                  <tr>
                                    {Object.keys(config).map((key, idx) => (
                                      <th key={idx} className="text-center" style={{ fontSize: "12px", padding: "4px" }}>{key}</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {Object.values(config).map((value, idx) => (
                                      <td key={idx} className="text-center" style={{ fontSize: "12px", padding: "4px" }}>{value}</td>
                                    ))}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>

                            {/* Error Statistics Section */}
                            {stats.totalRuns > 0 ? (
                              stats.runsWithErrors > 0 ? (
                                <div className="mt-4">
                                  <h5 className="text-center mb-3">Error Statistics</h5>
                                  <Table striped bordered className="shadow-sm" size="sm">
                                    <thead className="table-light">
                                      <tr>
                                        <th>Error Code</th>
                                        <th>Number of Errors</th>
                                        <th>Percent of Errors</th>
                                        <th>Percent of Total Runs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {errorStats[config.software_config].errorFrequencies
                                        .filter(error => error.count > 0) // Hide rows where number of errors is 0
                                        .map((error, idx) => {
                                          const totalErrors = errorStats[config.software_config].runsWithErrors || 1;
                                          const totalRuns = errorStats[config.software_config].totalRuns || 1;

                                          const percentOfErrors = ((error.count / totalErrors) * 100).toFixed(2);
                                          const percentOfTotalRuns = ((error.count / totalRuns) * 100).toFixed(2);

                                          return (
                                            <tr key={idx}>
                                              <td className="text-center">{error.errorcode || "N/A"}</td>
                                              <td className="text-center">{error.count}</td>
                                              <td className="text-center">{`${percentOfErrors}%`}</td>
                                              <td className="text-center">{`${percentOfTotalRuns}%`}</td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <p className="text-center text-muted mt-3">No errors</p>
                              )
                            ) : <p className="text-center text-muted mt-3">No runs found</p>} 
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  );
};

export default SoftwareVersionTable;